<template>
  <v-list-item class="align-stretch py-4" :style="{borderTop: index !== 0 ? '0.5px solid #000000' : ''}">
    <v-img :src="image" class="order-product__image"></v-img>
    <div class="d-flex flex-column justify-space-between my-sm-5 ml-sm-5 my-2 ml-2">
      <h3 class="order-product__name" @click="toProductPage()">{{ product.name }}</h3>
      <div class="order-product__count">
        <span>{{ orderCount ? orderCount : count }}</span> шт.
        <div v-if="cart" class="d-flex flex-column position-absolute order-product__count-btns">
          <v-icon :size="windowWidth > 599 ? 20 : 14" @click="addProductCount()">mdi-plus</v-icon>
          <v-icon :size="windowWidth > 599 ? 20 : 14" @click="lessProductCount()">mdi-minus</v-icon>
        </div></div>
      <v-btn text plain v-if="cart" class="order-product__button" color="rgba(255, 0, 0, 0.7)" @click="removeProduct()">Удалить<v-icon right>mdi-close</v-icon></v-btn>
    </div>
  </v-list-item>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['product', 'category', 'index', 'cart', 'orderCount'],
  data() {
    return {
      count: 0
    }
  },
  mounted() {
    this.count = this.storeCount
  },
  computed: {
    ...mapGetters({
      getCount: 'cart/getProductCount'
    }),
    storeCount() {
      return this.getCount(this.product.id)
    },
    image() {
      return process.env.VUE_APP_API_URL + '/api/image/getProductImage/' + this.product.preview
    },
    getValidPathForCategory() {
      return this.category.name.split(' ').join('-').toLowerCase() + '.' + this.category.id
    },
    getValidPathForProduct() {
      return this.product.name.split(' ').join('-').toLowerCase() + '.' + this.product.id
    },
    windowWidth() {
      return window.innerWidth
    }
  },
  methods: {
    toProductPage() {
      this.$router.push({name: 'product-page', params: {category: this.getValidPathForCategory, productName: this.getValidPathForProduct}})
    },
    removeProduct() {
      this.$store.dispatch('cart/removeProduct', this.product.id)
    },

    addProductCount() {
      this.count++
      this.$store.dispatch('cart/addProductCount', this.product.id)
    },
    lessProductCount() {
      this.count--
      this.$store.dispatch('cart/lessProductCount', this.product.id)
    }
  }
}
</script>

<style lang="sass">

.order
  &-product
    &__image
      border-radius: 8px
      max-width: 170px
      min-height: 150px
    &__name
      font-family: "Roboto Medium"
      font-size: 16px
      cursor: pointer
    &__count
      position: relative
      width: 100px
      padding: 8px
      border: 1px solid #000000
      border-radius: 6px
      text-align: center
      font-family: "Roboto Medium"
      font-size: 18px
      &-btns
        right: -30px
        top: 0
    &__button
      position: absolute
      right: 16px
      bottom: 16px
      text-transform: unset

@media (max-width: 1263.98px)
  .order
    &-product
      &__image
        max-width: 130px
        min-height: 100px
      &__name
        font-size: 16px
      &__count
        width: 80px
        font-size: 16px
      &__button
        bottom: 8px

@media (max-width: 959.98px)
  .order
    &-product
      &__image
        max-width: 110px
        min-height: 80px
      &__name
        font-size: 16px
      &__count
        width: 80px
        font-size: 16px
      &__button
        bottom: 8px

@media (max-width: 599.98px)
  .order
    &-product
      &__image
        max-width: 70px
        min-height: 70px
        max-height: 70px
      &__name
        font-size: 12px
      &__count
        width: 50px
        font-size: 10px
        padding: 2px
        &-btns
          right: -20px
          top: 0
      &__button
        bottom: 8px
        right: 0
        font-size: 10px !important

</style>
