<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6223 0.247447C19.2812 -0.0387535 18.7453 -0.0797036 18.1913 0.140347H18.1904C17.6077 0.371648 1.69614 7.12347 1.0484 7.39932C0.930588 7.43982 -0.09834 7.81962 0.00764594 8.66562C0.10226 9.42838 0.929223 9.74428 1.03021 9.78073L5.07541 11.151C5.34379 12.0348 6.33314 15.2955 6.55194 15.9921C6.6884 16.4263 6.91083 16.9969 7.30066 17.1144C7.64273 17.2449 7.98298 17.1256 8.20314 16.9546L10.6763 14.6853L14.6687 17.7655L14.7638 17.8218C15.0349 17.9406 15.2946 18 15.5425 18C15.7341 18 15.9178 17.9645 16.0934 17.8934C16.6916 17.6503 16.9308 17.0865 16.9558 17.0226L19.938 1.6879C20.12 0.868899 19.8671 0.452198 19.6223 0.247447ZM8.643 11.6991L7.27837 15.2991L5.91375 10.7991L16.3759 3.14906L8.643 11.6991Z" fill="#FFFFFF"/>
  </svg>
</template>

<script>
export default {
  name: "IconTelegramBlack"
}
</script>

<style scoped>

</style>
