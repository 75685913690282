import Vue from 'vue';
import Vuex from 'vuex';
import {auth} from "@/store/modules/auth.module"
import {notify} from "@/store/modules/notify.module"
import {form} from "@/store/modules/form.module"
import {mails} from "@/store/modules/mail.module"
import {cart} from "@/store/modules/cart.module"

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth,
    notify,
    form,
    mails,
    cart
  }
});
