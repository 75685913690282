export const form = {
  namespaced: true,
  state: {
    show: false,
    data: {
      secondName: '',
      name: '',
      surname: '',
      birthday: '',
      phone: '',
      email: '',
      address: '',
      TIN: '',
      hear: ''
    }
  },
  actions: {
    toggleDialog({commit}) {
      commit('toggleDialog')
    }
  },
  mutations: {
    toggleDialog(state) {
      state.show = !state.show
    }
  }
}
