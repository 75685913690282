import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/api/auth/'
import store from '@/store'
import router from "@/router";

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        login: user.login,
        password: user.password
      }, {
        withCredentials: true
      })
      .then(response => {
        if (response.data.error) {
          return response.data
        }
        localStorage.setItem('user', JSON.stringify(response.data));
        return response.data
      });
  }
  register(user) {
    return axios
      .post(API_URL + 'signup', {
        login: user.login,
        password: user.password
      }, {
        withCredentials: true
      })
      .then(response => {
        if (response.data.error) {
          return response.data
        }
        return response.data
      });
  }

  logout(type) {
    localStorage.removeItem('user');
    return axios
      .post(API_URL + 'logout', null, {
        withCredentials: true
      })
      .then(response => {
        let message = 'Вы вышли'
        if (type === 'error') {
          message = 'Необходима повторная авторизация'
        }
        router.push('/')
        store.dispatch('notify/newNotify', {type: type ? type : 'success', text: message})
        return response.data
      });
  }

  checkOnAdmin() {
    return axios.get(API_URL + 'checkOnAdmin', {
      withCredentials: true
    })
  }
}

export default new AuthService();
