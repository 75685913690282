let products = JSON.parse(localStorage.getItem('products'))

export const cart = {
  namespaced: true,
  state: {
    products: products !== null ? products : []
  },
  actions: {
    addToCart({commit}, data) {
      let storage = JSON.parse(localStorage.getItem('products'))
      if (storage && storage.length) {
        let existsIdx = storage.findIndex(el => el.id === data.product.id)
        if (existsIdx !== -1) {
          storage[existsIdx].count = Number(storage[existsIdx].count) + Number(data.count)
        } else {
          data.product.count = data.count
          storage.push(data.product)
        }
        localStorage.setItem('products', JSON.stringify(storage))
      } else {
        data.product.count = data.count
        localStorage.setItem('products', JSON.stringify([data.product]))
      }
      commit('addToCart', data)
    },
    clearCart({commit}) {
      if (JSON.parse(localStorage.getItem('products'))) {
        localStorage.removeItem('products')
      }
      commit('clearCart')
    },
    removeProduct({commit}, id) {
      let storage = JSON.parse(localStorage.getItem('products'))
      let existsIdx = storage.findIndex(el => el.id === id)
      storage.splice(existsIdx, 1)
      localStorage.setItem('products', JSON.stringify(storage))
      commit('removeProduct', id)
    },

    addProductCount({commit}, id) {
      let storage = JSON.parse(localStorage.getItem('products'))
      let existsIdx = storage.findIndex(el => el.id === id)
      if (existsIdx !== -1) {
        storage[existsIdx].count = Number(storage[existsIdx].count) + 1
        localStorage.setItem('products', JSON.stringify(storage))
        commit('addProductCount', id)
      } else {
        console.log('error')
      }
    },
    lessProductCount({commit}, id) {
      let storage = JSON.parse(localStorage.getItem('products'))
      let productIdx = storage.findIndex(el => el.id === id)
      if (productIdx !== -1) {
        storage[productIdx].count = Number(storage[productIdx].count) - 1

        if (Number(storage[productIdx].count) <= 0) {
          storage.splice(productIdx, 1)
        }
        localStorage.setItem('products', JSON.stringify(storage))
        commit('lessProductCount', id)
      } else {
        console.log('error')
      }
    },
  },
  getters: {
    getCartCount(state) {
      return state.products.length
    },

    getProductCount: (state) => id => {
      return state.products.find(el => el.id === id).count
    }
  },
  mutations: {
    addToCart(state, data) {
      let existsIdx = state.products.findIndex(el => el.id === data.product.id)
      if (existsIdx !== -1) {
        state.products[existsIdx].count = Number(state.products[existsIdx].count) + Number(data.count)
      } else {
        data.product.count = Number(data.count)
        state.products.push(data.product)
      }
    },
    clearCart(state) {
      state.products = []
    },
    removeProduct(state, id) {
      let index = state.products.findIndex(el => el.id === id)
      state.products.splice(index, 1)
    },

    addProductCount(state, id) {
      let productIdx = state.products.findIndex(el => el.id === id)
      if (productIdx !== -1) {
        state.products[productIdx].count = Number(state.products[productIdx].count + 1)
      } else {
        console.log('error')
      }
    },
    lessProductCount(state, id) {
      let productIdx = state.products.findIndex(el => el.id === id)
      if (productIdx !== -1) {
        state.products[productIdx].count = Number(state.products[productIdx].count - 1)
        if (Number(state.products[productIdx].count) <= 0) {
          state.products.splice(productIdx, 1)
        }
      } else {
        console.log('error')
      }
    }
  }
}
