export const notify = {
  namespaced: true,
  state: {
    newNotify: {
      show: false,
      text: '',
      type: null
    },
  },
  actions: {
    newNotify({commit}, data) {
      commit('newNotify', data)
      setTimeout(() => {
        commit('hideNotify')
      }, 3000)
    }
  },
  mutations: {
    newNotify(state, notify) {
      state.newNotify.text = notify.text
      state.newNotify.show = true
      state.newNotify.type = notify.type
    },
    hideNotify(state) {
      state.newNotify.show = false
    },
  }
}
