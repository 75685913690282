import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import IconTelegram from '@/components/customIcons/IconTelegram'
import IconTelegramBlack from '@/components/customIcons/IconTelegramBlack'
import IconTelegramWhite from '@/components/customIcons/IconTelegramWhite'

import IconWhatsApp from '@/components/customIcons/IconWhatsApp'
import IconWhatsAppBlack from '@/components/customIcons/IconWhatsAppBlack'
import IconWhatsAppWhite from '@/components/customIcons/IconWhatsAppWhite'

Vue.use(Vuetify);

export default new Vuetify({
    theme: { dark: false },
    icons: {
        values: {
            telegram: {
                component: IconTelegram,
            },
            telegramBlack: {
                component: IconTelegramBlack,
            },
            telegramWhite: {
                component: IconTelegramWhite,
            },
            whatsApp: {
                component: IconWhatsApp,
            },
            whatsAppBlack: {
                component: IconWhatsAppBlack,
            },
            whatsAppWhite: {
                component: IconWhatsAppWhite,
            }
        },
    },
});
