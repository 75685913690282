<template>
  <svg width="100%" height="100%" viewBox="0 0 400 400"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">

    <g transform="rotate(0,200,200)">
      <path id="p1" d="M150,200A50,150,0,0,1,250,200A50,150,0,0,1,150,200z"
            style="fill:none;stroke:rgba(0,0,0, 0.5);stroke-dasharray:3,2;" />
      <circle r="5" style="fill:#FFA000">
        <animateMotion dur="1s" repeatCount="indefinite">
          <mpath xlink:href="#p1" />
        </animateMotion>
      </circle>
    </g>
    <g transform="rotate(180,200,200)">
      <circle r="5" style="fill:#018ABE">
        <animateMotion dur="1s" repeatCount="indefinite">
          <mpath xlink:href="#p1" />
        </animateMotion>
      </circle>
    </g>

    <g transform="rotate(60,200,200)">
      <use xlink:href="#p1" />
      <circle r="5" style="fill:#FFA000">
        <animateMotion dur="1.1s" repeatCount="indefinite">
          <mpath xlink:href="#p1" />
        </animateMotion>
      </circle>
    </g>
    <g transform="rotate(240,200,200)">
      <circle r="5" style="fill:#018ABE">
        <animateMotion dur="1.1s" repeatCount="indefinite">
          <mpath xlink:href="#p1" />
        </animateMotion>
      </circle>
    </g>

    <g transform="rotate(120,200,200)">
      <use xlink:href="#p1" />
      <circle r="5" style="fill:#018ABE">
        <animateMotion dur="1.2s" repeatCount="indefinite">
          <mpath xlink:href="#p1" />
        </animateMotion>
      </circle>
    </g>
    <g transform="rotate(300,200,200)">
      <circle r="5" style="fill:#FFA000">
        <animateMotion dur="1.2s" repeatCount="indefinite">
          <mpath xlink:href="#p1" />
        </animateMotion>
      </circle>
    </g>

    <g id="nucleus" style="stroke:rgb(150,150,150);stroke-width:0.5px;">
      <circle cx="204" cy="196" r="8" style="fill:#018ABE;" />
      <circle cx="194" cy="194" r="8" style="fill:#FFA000;" />
      <circle cx="194" cy="200" r="8" style="fill:#018ABE;" />
      <circle cx="206" cy="206" r="8" style="fill:#FFA000;" />
    </g>

  </svg>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped>
</style>
