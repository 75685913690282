import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/style/fonts.sass'
import './assets/style/reset.sass'
import './assets/style/style.sass'
import { VueReCaptcha } from 'vue-recaptcha-v3'


Vue.use(VueReCaptcha, { siteKey: '6LdpRuIlAAAAAMOBjrgqsaw2pVQ3Sqq7Rhz7OsQI' })
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueMask from 'v-mask'
Vue.use(VuePlyr);
Vue.use(VueMask);

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
