import OrderService from "@/services/order.service";

export const mails = {
  namespaced: true,
  state: {
    registers: [],
    orders: []
  },
  actions: {
    getMails({commit}) {
      OrderService.getOrders().then(response => {
        response.data.map(el => {
          el.date = formattedDate(el.createdAt)
        })
        let registers = response.data.filter(el => !(el.orderProducts && el.orderProducts.length))
        let orders = response.data.filter(el => el.orderProducts && el.orderProducts.length)

        commit('setMails', {registers, orders})
      })
    },
    toggleRead({commit}, data) {
      OrderService.toggleRead(data).then(() => {
        commit('toggleRead', data)
      })
    }
  },
  getters: {
    getUnreadRegisters(state) {
      return state.registers.filter(el => !el.read).length
    },
    getUnreadOrders(state) {
      return state.orders.filter(el => !el.read).length
    },
  },
  mutations: {
    setMails(state, data) {
      state.registers = data.registers
      state.orders = data.orders
    },
    toggleRead(state, data) {
      let registerIndex = state.registers.findIndex(el => el.id === data.id)
      if (registerIndex !== -1) {
        state.registers[registerIndex].read = data.status
      } else {
        state.orders[state.orders.findIndex(el => el.id === data.id)].read = data.status
      }
    }
  }
}

function formattedDate(date) {
  let opt = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', hour12: false
  };
  const formattedDate = new Date(Date.parse(date))
  return formattedDate.toLocaleString('ru', opt)
}
