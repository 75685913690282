<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0C4.02975 0 0 4.02975 0 9C0 10.6884 0.474256 12.2626 1.2832 13.6128L0.0805664 18L4.56152 16.8237C5.87202 17.5688 7.38487 18 9 18C13.9703 18 18 13.9703 18 9C18 4.02975 13.9703 0 9 0ZM5.91943 4.80176C6.06568 4.80176 6.21595 4.80087 6.3457 4.80762C6.5062 4.81137 6.68089 4.82312 6.84814 5.19287C7.04689 5.63237 7.47966 6.73493 7.53516 6.84668C7.59066 6.95843 7.62998 7.09008 7.55273 7.23633C7.47923 7.38633 7.44101 7.47711 7.33301 7.60986C7.22126 7.73886 7.09881 7.89908 6.99756 7.99658C6.88581 8.10833 6.77041 8.23086 6.89941 8.45361C7.02841 8.67636 7.47642 9.40659 8.13867 9.99609C8.98992 10.7566 9.70814 10.9903 9.93164 11.1021C10.1551 11.2138 10.2846 11.1964 10.4136 11.0464C10.5463 10.9001 10.9711 10.3983 11.1211 10.1748C11.2673 9.9513 11.4166 9.98998 11.6191 10.0635C11.8246 10.137 12.9205 10.6768 13.144 10.7886C13.3675 10.9003 13.5141 10.9556 13.5703 11.0464C13.6281 11.1401 13.6281 11.5864 13.4429 12.1069C13.2576 12.6267 12.3479 13.1293 11.9399 13.1646C11.5282 13.2028 11.1439 13.3496 9.26367 12.6094C6.99567 11.7161 5.56536 9.39316 5.45361 9.24316C5.34186 9.09691 4.54541 8.03543 4.54541 6.94043C4.54541 5.84168 5.12149 5.30358 5.32324 5.08008C5.52874 4.85658 5.76943 4.80176 5.91943 4.80176Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: "IconWhatsApp"
}
</script>

<style scoped>

</style>
