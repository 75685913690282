import AuthService from '../../services/auth.service'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? {status: {loggedIn: true}, user, dialog: false}
  : {status: {loggedIn: false}, user: null, dialog: false}

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({commit}, user) {
      return AuthService.login(user).then(response => {
        if (response.error) {
          commit('loginFailure')
        } else {
          commit('loginSuccess', response)
        }
        return Promise.resolve(response)
      })
    },
    register({commit}, user) {
      return AuthService.register(user).then(response => {
        if (response.error) {
          commit('loginFailure')
        } else {
          console.log('okay')
        }
        return Promise.resolve(response)
      })
    },
    logout({commit}, type) {
      AuthService.logout(type).then(
        () => {
          commit('logout')
        }
      )
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
      state.dialog = false
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    }
  }
}
