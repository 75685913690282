<template>
  <v-app>
    <v-app-bar
        v-show="!firstLoading"
        app
        :shrink-on-scroll="windowWidth > 959"
        :height="windowWidth > 959 ? 90 : 65"
        class="navbar"
        :class="{'navbar-gradient__off': isAboutPage && !pageScrollOnShrink}"
        scroll-threshold="500"
        elevate-on-scroll
        :color="isAboutPage && !pageScrollOnShrink ? 'rgba(255, 255, 255, 0)' : '#FFFFFF'">
      <v-container class="py-0 h-100">
        <v-row class="my-0 h-100">
          <v-col cols="6" sm="4" md="1" class="d-flex py-0 position-relative h-100">
            <div class="navbar-logo">
              <v-img v-if="isAboutPage && !pageScrollOnShrink && !loading"
                     @click="$router.push({name: 'home'})"
                     :src="require('@/assets/icons/logo-white.png')"
                     height="100%" width="100%" contain
                     style="cursor: pointer"
              ></v-img>
              <v-img v-else
                     @click="$router.push({name: 'home'})"
                     :src="require('@/assets/icons/logo.png')"
                     height="100%" width="100%" contain
                     style="cursor: pointer"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="7" class="d-flex py-0 position-relative h-100" v-if="windowWidth > 959">
            <div class="navbar-links ml-xl-16" :class="{'navbar-links__white': isAboutPage && !pageScrollOnShrink && !loading}">
              <v-btn text plain class="px-lg-4 px-md-2" :class="{'navbar-links__link-active': activeLink === 'home'}" :to="{name: 'home'}">Главная</v-btn>
              <v-btn text plain class="px-lg-4 px-md-2" :class="{'navbar-links__link-active': activeLink === 'about'}" :to="{name: 'about'}">О компании</v-btn>
              <v-btn text plain class="px-lg-4 px-md-2" :class="{'navbar-links__link-active': activeLink === 'products'}" :to="{name: 'products'}">Продукция</v-btn>
              <v-btn text plain class="px-lg-4 px-md-2" :class="{'navbar-links__link-active': activeLink === 'business'}" :to="{name: 'business'}">Бизнес</v-btn>
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  transition="slide-y-transition"
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      text plain class="px-lg-4 px-md-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Контакты
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="pb-0 pt-3" style="font-size: 18px">Администраторы сайта</v-card-title>
                  <v-list>
                    <v-list-item>
                      <v-list-item-avatar>
                        <img
                            :src="require('@/assets/icons/avatar1.jpg')"
                            alt="Vlad"
                            style="object-fit: cover"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Владислав</v-list-item-title>
                        <v-list-item-subtitle style="cursor: pointer" @click="openTel('79994755394')">+79994755394</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <div class="d-flex">
                          <v-btn
                              icon
                              @click="openTG('79994755394')"
                          >
                            <v-icon>$telegram</v-icon>
                          </v-btn>
                          <v-btn
                              icon
                              @click="openWA('79994755394')"
                          >
                            <v-icon>$whatsApp</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-list-item-avatar>
                        <img
                            :src="require('@/assets/icons/avatar2.jpeg')"
                            alt="Alina"
                            style="object-fit: cover"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Алина</v-list-item-title>
                        <v-list-item-subtitle style="cursor: pointer" @click="openTel('79069633285')">+79069633285</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <div class="d-flex">
                          <v-btn
                              icon
                              @click="openTG('79069633285')"
                          >
                            <v-icon>$telegram</v-icon>
                          </v-btn>
                          <v-btn
                              icon
                              @click="openWA('79069633285')"
                          >
                            <v-icon>$whatsApp</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-list-item-avatar>
                        <img
                            :src="require('@/assets/icons/avatar3.jpeg')"
                            alt="Denis"
                            style="object-fit: cover"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Денис</v-list-item-title>
                        <v-list-item-subtitle style="cursor: pointer" @click="openTel('79133695800')">+79133695800</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <div class="d-flex">
                          <v-btn
                              icon
                              @click="openTG('79133695800')"
                          >
                            <v-icon>$telegram</v-icon>
                          </v-btn>
                          <v-btn
                              icon
                              @click="openWA('79133695800')"
                          >
                            <v-icon>$whatsApp</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title style="cursor: pointer" @click="openEmail('atomygrace2023@gmail.com')">atomygrace2023@gmail.com</v-list-item-title>
                        <v-list-item-subtitle>Электронная почта</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        text
                        @click="menu = false"
                    >
                      Закрыть
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="6" sm="8" md="4" class="d-flex py-0 position-relative h-100 justify-end">
            <v-menu
                v-model="cartMenu"
                :close-on-content-click="false"
                :nudge-width="cartWidth"
                transition="slide-y-transition"
                offset-y
                :left="windowWidth > 959"
                :right="windowWidth < 960"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge class="align-self-center" :content="cartCount" overlap color="#FFA000" offset-y="20" offset-x="15" :value="cartCount !== 0">
                  <v-btn
                      icon plain class="px-lg-4 px-md-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="#018ABE">mdi-cart</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-card class="pa-4">

                <v-card-title class="pa-0 justify-space-between">
                  <span class="cart-title">Корзина</span>
                  <div>
                    <v-btn class="cart-clear" text @click="clearCart()" plain style="text-transform: unset" color="rgba(0, 0, 0, 0.56)">
                      <v-icon left>mdi-cart-remove</v-icon> Очистить корзину
                    </v-btn>
                    <v-btn class="cart-close" icon @click="cartMenu = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-card-title>
                <v-divider class="mt-1"></v-divider>
                <v-card-text class="pa-0">
                  <v-list class="cart-products-list">
                    <CustomCartAndOrderCard v-for="(product, index) of cartProducts" :key="'cartProduct-' + product.id"
                                            :product="product" :category="product.category" :count="product.count" :index="index" :cart="true"
                    ></CustomCartAndOrderCard>
                    <span v-if="!cartProducts.length" class="d-flex justify-center my-4">В корзине пусто</span>
                  </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="mt-3 justify-sm-end justify-center">
                  <v-btn class="button cart-submit py-5 px-4" color="#FFA000" dark @click="$store.dispatch('form/toggleDialog'); order = true">Зарегистрироваться и заказать</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <div class="navbar-contacts mr-lg-10" v-if="windowWidth > 599 && !loggedIn">
              <v-btn @click="openTG('79994755394')" text plain><v-icon left>$telegram</v-icon>+7 (999) 475-53-94</v-btn>
            </div>
            <div class="navbar-btn" v-if="windowWidth > 599 && !loggedIn">
              <v-btn @click="$store.dispatch('form/toggleDialog')" class="button px-lg-7 py-lg-2 px-md-5 px-7" color="#018ABE">Регистрация</v-btn>
            </div>
            <div class="d-flex h-100 align-center" v-if="loggedIn && windowWidth > 599">
              <v-badge :content="unreadRegisters" overlap color="#FFA000" offset-y="20" offset-x="15" :value="unreadRegisters !== 0">
                <v-btn icon @click="$route.query.tab && $route.query.tab === '0' ? false : $router.push({name: 'mails', query: {tab: '0'}})">
                  <v-icon color="#018ABE">mdi-email</v-icon>
                </v-btn>
              </v-badge>
              <v-badge :content="unreadOrders" overlap color="#FFA000" offset-y="20" offset-x="15" :value="unreadOrders !== 0">
                <v-btn icon @click="$route.query.tab && $route.query.tab === '1' ? false : $router.push({name: 'mails', query: {tab: '1'}})">
                  <v-icon color="#018ABE">mdi-swap-horizontal-bold</v-icon>
                </v-btn>
              </v-badge>
              <v-btn text class="ml-4" @click="$store.dispatch('auth/logout')"><v-icon left color="#018ABE" size="24">mdi-logout</v-icon>Выйти</v-btn>
            </div>
            <v-menu v-if="loggedIn && windowWidth < 600"
                    v-model="adminMenu"
                    transition="slide-y-transition"
                    offset-y
                    close-on-click
                    left>
              <template v-slot:activator="{ on, attrs }">
                <v-badge color="#FFA000"
                         overlap
                         offset-y="20" offset-x="15"
                         :content="unreadRegisters + unreadOrders"
                         :value="unreadRegisters !== 0 || unreadOrders !== 0" class="align-self-center">
                  <v-btn
                      icon plain class="px-lg-4 px-md-2 align-self-center"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon color="#018ABE">mdi-shield-crown</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-list>
                <v-list-item dense @click="$route.query.tab && $route.query.tab === '0' ? false : $router.push({name: 'mails', query: {tab: '0'}})">
                  <v-list-item-icon class="mr-2">
                    <v-badge dot color="#FFA000" :value="unreadRegisters !== 0">
                      <v-icon color="#018ABE">mdi-email</v-icon>
                    </v-badge>
                  </v-list-item-icon>
                  <v-list-item-title>Регистрации</v-list-item-title>
                </v-list-item>
                <v-list-item dense @click="$route.query.tab && $route.query.tab === '1' ? false : $router.push({name: 'mails', query: {tab: '1'}})">
                  <v-list-item-icon class="mr-2">
                    <v-badge dot color="#FFA000" :value="unreadOrders !== 0">
                      <v-icon color="#018ABE">mdi-swap-horizontal-bold</v-icon>
                    </v-badge>
                  </v-list-item-icon>
                  <v-list-item-title>Заказы</v-list-item-title>
                </v-list-item>
                <v-list-item dense @click="$store.dispatch('auth/logout')">
                  <v-list-item-icon class="mr-2"><v-icon color="#018ABE">mdi-logout</v-icon></v-list-item-icon>
                  <v-list-item-title>Выйти</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="navbar-mobile__icon ml-sm-2" v-if="windowWidth < 960">
              <v-app-bar-nav-icon @click="mobileNavbar = true"></v-app-bar-nav-icon>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main v-if="firstLoading || loading" class="pa-0 ma-0 position-fixed" style="top: 0; right: 0; bottom: 0; left: 0; background: #ffffff; z-index: 1">
      <div style="height: 100vh; display: flex; justify-content: center">
        <div style="width: 250px">
          <Loading></Loading>
        </div>
      </div>
    </v-main>

    <v-main :style="{'opacity': !firstLoading && !loading ? 1 : 0, 'transition': '0.5s all'}">
      <div class="business-background" v-if="activeLink === 'business'"></div>

      <router-view :windowWidth="windowWidth"/>

      <v-container fluid class="mt-md-15 mt-7 footer position-relative" style="z-index: 1" :class="{'footer-gradient__off': activeLink === 'business'}">
        <v-row>
          <v-col>
            <v-container class="footer-map py-0">
              <v-row>
                <v-col cols="12" class="py-0 footer-mobile__background">
                  <v-img :src="require('@/assets/images/map.png')" class="position-relative pb-md-16 pb-xl-0" contain v-if="windowWidth > 599">
                    <v-card class="footer-contacts pa-5 pr-16 pa-md-0" :color="windowWidth > 959 ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.5)'" flat width="auto">
                      <v-card-title class="footer-contacts__title pa-0 mb-xl-4 mb-lg-2">Свяжитесь с нами</v-card-title>
                      <v-list class="footer-contacts__list pa-0 ma-0">
                        <v-list-item class="px-0 footer-contacts__list-item">
                          <v-btn plain class="px-1">
<!--                            <v-icon left>mdi-phone</v-icon>-->
                            <v-icon @click="openTG('79994755394')" left>{{ windowWidth > 959 ? '$telegramBlack' : '$telegramWhite' }}</v-icon>
                            <v-icon @click="openWA('79994755394')" left class="ml-1">{{ windowWidth > 959 ? '$whatsAppBlack' : '$whatsAppWhite' }}</v-icon>
                            <span @click="openTel('79994755394')">+7 (999) 475-53-94</span>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="px-0 footer-contacts__list-item py-xl-3 py-lg-1">
                          <v-btn plain class="px-1">
                            <v-icon @click="openTG('79069633285')" left>{{ windowWidth > 959 ? '$telegramBlack' : '$telegramWhite' }}</v-icon>
                            <v-icon @click="openWA('79069633285')" left class="ml-1">{{ windowWidth > 959 ? '$whatsAppBlack' : '$whatsAppWhite' }}</v-icon>
                            <span @click="openTel('79069633285')">+7 (906) 963-32-85</span>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="px-0 footer-contacts__list-item">
                          <v-btn plain class="px-1">
                            <v-icon @click="openTG('79133695800')" left>{{ windowWidth > 959 ? '$telegramBlack' : '$telegramWhite' }}</v-icon>
                            <v-icon @click="openWA('79133695800')" left class="ml-1">{{ windowWidth > 959 ? '$whatsAppBlack' : '$whatsAppWhite' }}</v-icon>
                            <span @click="openTel('79133695800')">+7 (913) 369-58-00</span>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="px-0 footer-contacts__list-item"><v-btn @click="openEmail('atomygrace2023@gmail.com')" plain class="px-1" style="text-transform: none"><v-icon left>mdi-email</v-icon>atomygrace2023@gmail.com</v-btn></v-list-item>
                      </v-list>
                      <v-card-text class="d-flex flex-column pa-0">
                        <span class="footer-contacts__add-text mt-lg-1 mb-lg-2 mb-1">
                          или зарегистрируйтесь и мы с вами<br> свяжемся
                        </span>
                        <v-btn @click="$store.dispatch('form/toggleDialog')" class="footer-button button py-lg-6" color="#FFA000">Регистрация</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-img>
                  <v-card v-else class="footer-contacts pa-5 pr-16 pa-md-0" :color="windowWidth > 959 ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.5)'" flat width="auto">
                    <v-card-title class="footer-contacts__title pa-0 mb-xl-4 mb-lg-2">Свяжитесь с нами</v-card-title>
                    <v-list class="footer-contacts__list pa-0 ma-0">
                      <v-list-item class="px-0 footer-contacts__list-item">
                        <v-btn plain class="px-1">
                          <v-icon @click="openTG('79994755394')" left>$telegramWhite</v-icon>
                          <v-icon @click="openWA('79994755394')" left class="ml-1">$whatsAppWhite</v-icon>
                          <span @click="openTel('79994755394')">+7 (999) 475-53-94</span>
                        </v-btn>
                      </v-list-item>
                      <v-list-item class="px-0 footer-contacts__list-item py-xl-3 py-lg-1">
                        <v-btn plain class="px-1">
                          <v-icon @click="openTG('79069633285')" left>$telegramWhite</v-icon>
                          <v-icon @click="openWA('79069633285')" left class="ml-1">$whatsAppWhite</v-icon>
                          <span @click="openTel('79069633285')">+7 (906) 963-32-85</span>
                        </v-btn>
                      </v-list-item>
                      <v-list-item class="px-0 footer-contacts__list-item">
                        <v-btn plain class="px-1">
                          <v-icon @click="openTG('79133695800')" left>$telegramWhite</v-icon>
                          <v-icon @click="openWA('79133695800')" left class="ml-1">$whatsAppWhite</v-icon>
                          <span @click="openTel('79133695800')">+7 (913) 369-58-00</span>
                        </v-btn>
                      </v-list-item>
                      <v-list-item class="px-0 footer-contacts__list-item"><v-btn @click="openEmail('atomygrace2023@gmail.com')" plain class="px-1" style="text-transform: none"><v-icon left>mdi-email</v-icon>atomygrace2023@gmail.com</v-btn></v-list-item>
                    </v-list>
                    <v-card-text class="d-flex flex-column pa-0">
                        <span class="footer-contacts__add-text mt-lg-1 mb-lg-2 mb-1">
                          или зарегистрируйтесь и мы с вами<br> свяжемся
                        </span>
                      <v-btn @click="$store.dispatch('form/toggleDialog')" class="footer-button button py-lg-6" color="#FFA000">Регистрация</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" class="my-lg-8 my-md-6 my-4 py-0">
                  <div class="d-flex flex-column flex-sm-row justify-center justify-sm-space-between align-center footer-add">
                    <span>2019-2023 Atomy</span>
                    <span style="cursor: pointer" @click="$router.push({name: 'policy'})">Политика конфиденциальности</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <Notification></Notification>

    <v-dialog v-model="form.show"
              @click:outside="$store.dispatch('form/toggleDialog')"
              @keydown.esc="$store.dispatch('form/toggleDialog')"
              :width="windowWidth > 599 ? 'auto' : '100%'" :fullscreen="windowWidth < 600">
      <v-card min-width="320" max-width="600">
        <v-card-title>Регистрация</v-card-title>
        <div class="position-absolute" style="right: 24px; top: 16px">
          <v-icon @click="$store.dispatch('form/toggleDialog')" >mdi-close</v-icon>
        </div>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="4" class="pb-0">
                <v-text-field label="Фамилия *" v-model="form.data.secondName" :rules="rules.secondName"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0">
                <v-text-field label="Имя *" v-model="form.data.name" :rules="rules.name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0">
                <v-text-field label="Отчество *" v-model="form.data.surname" :rules="rules.surname"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0">
                <v-text-field label="Дата рождения *" v-model="form.data.birthday" :rules="rules.birthday" v-mask="'##.##.####'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0">
                <v-text-field label="Телефон *" v-model="form.data.phone" :rules="rules.phone" v-mask="'# (###) ###-##-##'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0">
                <v-text-field label="E-mail *" v-model="form.data.email" :rules="rules.email"></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field label="Адрес проживания *" v-model="form.data.address" :rules="rules.address"></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field label="ИНН (необязательно)" hide-details v-model="form.data.TIN" :rules="rules.TIN" v-mask="maskTIN"></v-text-field>
                <span class="mt-2 d-block" style="font-size: 14px; font-weight: bold; color: black; font-family: Roboto, sans-serif"><span style="color: #FFA000"> > </span>Указывая ИНН вы получаете возможность зарабатывать с Атоми</span>
              </v-col>
              <v-col cols="12">
                <v-textarea label="Откуда или от кого вы узнали о нас? *" v-model="form.data.hear" :rules="rules.hear" rows="3" no-resize></v-textarea>
              </v-col>
              <v-col cols="12" class="py-0">
                <div class="d-flex flex-column">
                  <v-btn @click="sendOrder()" class="button mb-3 py-sm-5 py-7" color="#FFA000" dark :width="windowWidth > 599 ? 250 : '100%'">Зарегистрироваться</v-btn>
                  <span style="font-size: 12px; font-family: Roboto, sans-serif">Нажимая кнопку "Зарегистрироваться" вы соглашаетесь с<br> <span style="cursor: pointer" @click="$router.push({name: 'policy'})">политикой конфиденциальности</span></span>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="mobileNavbar" fullscreen :scrollable="true">
      <v-sheet
          class="text-center position-relative overflow-y-auto mobile-navbar__gradient"
          height="100vh"
      >
        <div class="navbar-mobile__logo mt-sm-5 mt-12">
          <img :src="require('@/assets/icons/logo.png')" height="70px" width="auto" alt="logo" @click="$router.push({name: 'home'})"/>
        </div>
        <v-btn
            class="position-absolute"
            style="right: 30px; top: 30px"
            icon
            color="black"
            @click="mobileNavbar = !mobileNavbar"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="py-3">
          <v-list class="d-flex flex-column justify-center align-center" color="rgba(0,0,0,0)">
            <v-list-item>
              <v-btn text plain class="navbar-mobile__link-item" @click="$router.push({name: 'home'}); mobileNavbar = false">Главная</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text plain class="navbar-mobile__link-item" @click="$router.push({name: 'about'}); mobileNavbar = false">О компании</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text plain class="navbar-mobile__link-item" @click="$router.push({name: 'products'}); mobileNavbar = false">Продукция</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text plain class="navbar-mobile__link-item" @click="$router.push({name: 'business'}); mobileNavbar = false">Бизнес</v-btn>
            </v-list-item>
            <v-list-item>
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  transition="slide-y-transition"
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      text plain class="px-lg-4 px-md-2 navbar-mobile__link-item"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Контакты
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="pb-0 pt-3" style="font-size: 18px">Администраторы сайта</v-card-title>
                  <v-list>
                    <v-list-item>
                      <v-list-item-avatar>
                        <img
                            :src="require('@/assets/icons/avatar1.jpg')"
                            alt="Vlad"
                            style="object-fit: cover"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Владислав</v-list-item-title>
                        <v-list-item-subtitle>+79994755394</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <div class="d-flex">
                          <v-btn
                              icon
                              @click="openTG('79994755394')"
                          >
                            <v-icon>$telegram</v-icon>
                          </v-btn>
                          <v-btn
                              icon
                              @click="openWA('79994755394')"
                          >
                            <v-icon>$whatsApp</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-list-item-avatar>
                        <img
                            :src="require('@/assets/icons/avatar2.jpeg')"
                            alt="Alina"
                            style="object-fit: cover"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Алина</v-list-item-title>
                        <v-list-item-subtitle>+79069633285</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <div class="d-flex">
                          <v-btn
                              icon
                              @click="openTG('79069633285')"
                          >
                            <v-icon>$telegram</v-icon>
                          </v-btn>
                          <v-btn
                              icon
                              @click="openWA('79069633285')"
                          >
                            <v-icon>$whatsApp</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-list-item-avatar>
                        <img
                            :src="require('@/assets/icons/avatar3.jpeg')"
                            alt="Denis"
                            style="object-fit: cover"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Денис</v-list-item-title>
                        <v-list-item-subtitle>+79133695800</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <div class="d-flex">
                          <v-btn
                              icon
                              @click="openTG('79133695800')"
                          >
                            <v-icon>$telegram</v-icon>
                          </v-btn>
                          <v-btn
                              icon
                              @click="openWA('79133695800')"
                          >
                            <v-icon>$whatsApp</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>atomygrace2023@gmail.com</v-list-item-title>
                        <v-list-item-subtitle>Электронная почта</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        text
                        @click="menu = false"
                    >
                      Закрыть
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-list-item>
          </v-list>
        </div>
        <div class="d-flex flex-column justify-center">
          <div class="footer-contacts__phone mb-1">
            <v-btn plain text class="px-0" @click="openTel()"><v-icon left>mdi-phone</v-icon>+7 999 475 5394</v-btn>
          </div>
          <div class="navbar-contacts__social align-self-center mb-1">
            <v-btn icon plain small width="28px" height="28px" class="mr-2" @click="openWA()"><v-icon size="32px" color="#40C351">mdi-whatsapp</v-icon></v-btn>
            <v-btn icon plain small width="28px" height="28px" class="ml-2" @click="openTG()"><v-icon size="28px">$telegram</v-icon></v-btn>
          </div>
        </div>
        <v-list class="d-flex flex-column justify-center align-center" color="rgba(0,0,0,0)">
          <v-list-item>
            <v-btn @click="$store.dispatch('form/toggleDialog')" color="#018ABE" class="button px-7" dark>Регистрация</v-btn>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
import Notification from "@/components/oftenUsed/Notification";
import Loading from "@/components/Loading";
import orderService from "@/services/order.service";

import {mapGetters, mapState} from "vuex";
import CustomCartAndOrderCard from "@/components/CustomCartAndOrderCard";

export default {
  components: {CustomCartAndOrderCard, Loading, Notification},
  data() {
    return {
      firstLoading: true,
      loading: false,
      menu: false,
      cartMenu: false,
      adminMenu: false,
      mobileNavbar: false,
      pageScrollOnShrink: false,
      rules: {
        secondName: [v => !!v || 'Обязательное поле'],
        name: [v => !!v || 'Обязательное поле'],
        surname: [v => !!v || 'Обязательное поле'],
        birthday: [v => !!v || 'Обязательное поле'],
        phone: [
          v => !!v || 'Обязательное поле',
          // eslint-disable-next-line no-useless-escape
          v => !v || (/^(\+7|7|8)?[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(v) || 'Некорректный номер')
        ],
        email: [
          v => !!v || 'Введите e-mail',
          v => /.+@.+\..+/.test(v) || 'Некорректный e-mail',
        ],
        address: [v => !!v || 'Обязательное поле'],
        hear: [v => !!v || 'Обязательное поле'],
      },
      order: false
    }
  },
  computed: {
    ...mapGetters({
      unreadRegisters: "mails/getUnreadRegisters",
      unreadOrders: "mails/getUnreadOrders",
      cartCount: "cart/getCartCount"
    }),
    ...mapState({
      cartProducts: state => state.cart.products
    }),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
    windowWidth() {
      return window.innerWidth
    },
    isAboutPage() {
      return this.$route.name === 'about'
    },
    activeLink() {
      return this.$route.name
    },
    form() {
      return this.$store.state.form
    },
    maskTIN() {
      return this.form.data.TIN && this.form.data.TIN.length < 13 ? '####-#####-#' : '####-######-##'
    },

    cartWidth() {
      if (this.windowWidth > 1903) {
        return 600
      } else if (this.windowWidth > 1263) {
        return 500
      } else if (this.windowWidth > 599) {
        return 400
      } else {
        return 300
      }
    }
  },

  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch('mails/getMails').catch(() => {
        this.$store.dispatch('auth/logout')
      })
    }
  },

  created() {
    window.addEventListener('scroll', this.changeBgColorNavbar)
    setTimeout(() => {
      this.firstLoading = false
    }, 500)

    setTimeout(() => {
      const recaptcha = this.$recaptchaInstance
      recaptcha.hideBadge()
    }, 5000)
  },
  destroyed() {
    window.removeEventListener('scroll', this.changeBgColorNavbar)
  },
  methods: {
    scrollTop() {
      window.scrollTo({top: 0, x: 0, behavior: "smooth"})
    },
    changeBgColorNavbar() {
      this.pageScrollOnShrink = this.windowWidth > 1263 ? window.scrollY > 500 : this.windowWidth > 960 ? window.scrollY > 300 : window.scrollY > 200
    },
    openTG(phone) {
      window.open(`https://t.me/+${phone}`, '_blank');
    },
    openWA(phone) {
      window.open(`https://api.whatsapp.com/send/?phone=${phone}`, '_blank');
    },
    openTel() {
      let number = '+7 (999) 475 53-94'
      let phone = number.split(' ').join('')
      // eslint-disable-next-line no-useless-escape
      phone = phone.replace(/\(|\)|\-/g, '')
      if (number.startsWith('8' || '7')) {
        phone = phone.split('')
        phone.splice(0, 1, '+7')
        phone = phone.join('')
      }
      window.location.href = 'tel:' + phone
    },
    openEmail(email) {
      window.location.href = `mailto:${email}`
    },
    async sendOrder() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')

      let valid = this.$refs.form.validate()

      if (token && valid) {
        if (this.order) {
          await orderService.sendOrder({data: this.form.data, products: this.$store.state.cart.products, token}).then((response) => {
            if (response.data.error) {
              this.$store.dispatch('notify/newNotify', {text: 'Ошибка, повторите позже', type: 'error'})
            } else {
              this.$store.dispatch('notify/newNotify', {text: 'Спасибо за регистрацию, мы с вами свяжемся', type: 'success'})
              this.$store.dispatch('form/toggleDialog')
              this.$store.dispatch('cart/clearCart')
              this.order = false
              this.$refs.form.reset()
            }
          })
        } else {
          await orderService.sendOrder({data: this.form.data, token}).then((response) => {
            if (response.data.error) {
              this.$store.dispatch('notify/newNotify', {text: 'Ошибка, повторите позже', type: 'error'})
            } else {
              this.$store.dispatch('notify/newNotify', {text: 'Спасибо за регистрацию, мы с вами свяжемся', type: 'success'})
              this.$store.dispatch('form/toggleDialog')
              this.$refs.form.reset()
            }
          })
        }
      }
    },

    clearCart() {
      this.$store.dispatch('cart/clearCart')
    }
  },
  watch: {
    '$route' (to, from) {
      if(to.name !== from.name) {
        document.title = to.meta.title + ' | Atomy' || 'Atomy'
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    }
  },
};
</script>

<style lang="sass">
//*
//  outline: 1px red solid

//.theme--light.v-app-bar.v-toolbar.v-sheet
//  background-color: rgba(255, 255, 255, 0) !important
.v-ripple__container
  display: none !important

.plyr__poster
  background-size: cover !important

.business-background
  position: absolute
  top: -70px
  left: 0
  right: 0
  bottom: 0
  z-index: 0
  background-image: url("./assets/images/bg1business.svg")
  background-repeat: no-repeat
  background-size: cover

div.ql-toolbar.ql-snow, div.ql-container.ql-snow
  border: none

.products-tabs
  .v-tabs-bar__content
    width: 100% !important
    white-space: normal !important

.mobile-navbar__gradient
  background: linear-gradient(0deg, rgba(1, 138, 190, 0.63) -8.27%, rgba(255, 255, 255, 0.35) 97.97%)

.navbar
  height: 100px
  background: linear-gradient(180deg, rgba(1, 138, 190, 0.63) -8.27%, rgba(255, 255, 255, 0.35) 97.97%)
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15) !important
  &-gradient__off
    background: none
  &-logo
    position: relative
    height: 100%
    width: 100%
    align-self: center
    max-width: 78px
  &-links
    display: flex
    align-self: center
    transition: 0.3s all
    .v-btn
      color: #000000
      font-size: 16px
      //font-family: "Atomy Light"
      font-family: 'Roboto', sans-serif
      font-weight: 350
      text-transform: none
      transition: 0.3s all
      &:hover
        color: #018ABE
    &__white
      .v-btn
        color: #FFFFFF

    &__link-active.v-btn
      color: #FFA000
      transition: 0.3s all
      &:hover
        color: #FFA000
  &-contacts
    display: flex
    align-self: center
    .v-btn
      color: #000000
      font-size: 14px
      //font-family: "Atomy Medium"
      font-family: 'Roboto', sans-serif
      font-weight: normal
      text-transform: none
  &-btn
    display: flex
    align-self: center
    .v-btn
      color: #ffffff
      font-size: 14px

.cart-products-list
  max-height: 400px
  overflow-y: auto

.footer
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(108, 194, 230, 0.29) 33.35%, #018ABE 100%)
  &-gradient__off
    background: none
  &-contacts
    position: absolute
    bottom: 0
    left: 0
    background: none
    box-shadow: unset
    &__title
      font-size: 36px
    &__list
      background: none !important
      &-item
        min-height: 20px !important
        .v-btn
          font-size: 20px
    &__add-text
      font-size: 20px
  &-button.v-btn
    color: #FFFFFF
    font-size: 18px
    //font-family: "Atomy Medium"
    font-family: 'Roboto', sans-serif
    font-weight: normal
    text-transform: none
  &-add
    color: #000000
    font-size: 16px
    //font-family: "Atomy Medium"
    font-family: 'Roboto', sans-serif
    font-weight: normal

@media (max-width: 1903.98px)
  .footer
    &-contacts
      &__title
        font-size: 26px
      &__list
        &-item
          min-height: 18px !important
        .v-btn
          font-size: 18px
      &__add-text
        font-size: 16px
    &-button.v-btn
      font-size: 16px
@media (max-width: 1263.99px)
  .business-background
    top: 0px
  .navbar
    &-links
      .v-btn
        font-size: 14px
    &-contacts
      .v-btn
        font-size: 12px
  .footer
    &-contacts
      &__title
        font-size: 20px
      &__list
        &-item
          min-height: 16px !important
          height: 28px
        .v-btn
          font-size: 14px
      &__add-text
        font-size: 13px
    &-button.v-btn
      width: 200px
      font-size: 14px
@media (max-width: 959.98px)

  .products-tabs
    .v-tabs-bar__content
      width: auto !important
      white-space: normal !important

  .navbar
    &-logo
      max-width: 50px
    &-contacts
      .v-btn
        font-size: 12px
    .button
      font-size: 12px
    &-mobile
      &__icon
        display: flex
        flex-direction: column
        justify-content: center
      &__link-item
        text-transform: unset
  .footer
    &-contacts
      &__title
        color: white
      &__list
        &-item
          .v-btn
            color: white
      &__add-text
        color: white
    &-button.v-btn
      font-size: 14px

@media (max-width: 599.98px)
  .footer
    &-mobile__background
      background-image: url("./assets/images/map.png")
      background-size: contain
      background-position: center center
    &-contacts
      position: relative
    &-add
      color: #000000
      font-size: 14px
      //font-family: "Atomy Medium"
      font-family: 'Roboto', sans-serif
      font-weight: normal

  .cart
    &-title
      font-size: 16px
    &-clear
      font-size: 12px !important
      padding: 0 4px !important
    &-close
      height: 24px !important
      width: 24px !important
    &-submit
      font-size: 12px !important

    &-products-list
      max-height: 240px
</style>
