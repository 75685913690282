import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL + '/api/order'

class OrderService {
  sendOrder(data) {
    return axios.post(API_URL + '/sendOrder', data)
  }

  getOrders() {
    return axios.get(API_URL + '/getOrders', {
      withCredentials: true
    })
  }
  toggleRead(data) {
    return axios.post(API_URL + '/toggleRead', data, {
      withCredentials: true
    })
  }
}

export default new OrderService()
