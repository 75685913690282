import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthService from "@/services/auth.service";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView'),
    meta: { title: 'Главная' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView'),
    meta: { title: 'О нас' }
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/ProductsView'),
    meta: { title: 'Продукция' }
  },
  {
    path: '/products/:category/:productName',
    name: 'product-page',
    component: () => import('../views/ProductPageView'),
    meta: { title: 'Товар' }
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/BusinessView'),
    meta: { title: 'Бизнес' }
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/PolicyView'),
    meta: { title: 'Политика конфиденциальности' }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/admin/AuthView'),
    meta: { requiresAuth: false, adminPage: true, title: 'Вход в админку' }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/AdminView'),
    redirect: 'admin/mails',
    meta: { requiresAuth: true, adminPage: true, title: 'Административная панель' },
    children: [
      {
        path: 'mails',
        name: 'mails',
        component: () => import('../components/admin/AdminMails'),
        meta: { requiresAuth: true, adminPage: true, title: 'Регистрации и заказы' },
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import('../components/admin/AdminCategories'),
        meta: { requiresAuth: true, adminPage: true, title: 'Редактирование категорий' },
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');
  if (to.meta.adminPage) {
    if (to.name === 'auth') {
      if (loggedIn) {
        AuthService.checkOnAdmin().then(response => {
          if (response.data.isAdmin === true) {
            next('/admin')
          } else {
            next('/')
          }
        })
      } else {
        next()
      }
    } else if (!loggedIn) {
      next('/')
    } else {
      AuthService.checkOnAdmin().then(response => {
        if (response.data.isAdmin === true) {
          next()
        } else {
          next('/')
        }
      })
    }
  } else if (to.meta.requiresAuth && !loggedIn) {
    next('/')
  } else {
    next()
  }
});

export default router
